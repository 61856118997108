



























































































































































import * as _ from 'lodash'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { RequestPaging } from '@/utils/util'
import AddUnit from '@/components/goods/add-feedback.vue'
import AddFeedBack from '@/components/goods/feedback-info.vue'
import {
  apiDelSelectMaterial,
  apiGetAllMaterialForTopic,
  apiGetFormatTopic,
  apiGetMaterialByIds,
  apiGoodsLists,
  apiSetTopOrderAll

} from '@/api/goods'
import {
  apiFeedBackLists, apiUnitConfig, apiFeedBackProcess, apiFeedBackDel
} from '@/api/feedback'
import LsPagination from '@/components/ls-pagination.vue'
import { filterTableData } from '@/utils/table'
import Template from '@/views/shop/pages/template.vue'
import { commonAlert } from '@/utils/confirm'
import { error } from 'echarts/types/src/util/log'
import { State } from 'vuex-class'

@Component({
  components: {
    Template,
    LsPagination,
    AddUnit,
    AddFeedBack,


  }
})
export default class MaterialManage extends Vue {
  @State('user') user: any
  // 分页
  $refs!: { table: any, addUnit:any, addFeedBack:any }
  pager: RequestPaging = new RequestPaging()
  materialList: any = []
  // 选中的集合
  multipleSelection: any = []
  // 选中的索引
  multipleIndex: any = []
  // 获取所有主题
  topicList: any = []
  isStatus: any = false
  // // 是否在最上
  // isHaveTop: boolean = false
  // // 是否在最下
  // isHaveBottom: boolean = false
  // 默认全都关闭
  isFalse = true
  isTopicMaterial: any = true
  form: any = {
    keyword: '',
    search: '',
    order: '',
    order_by: '',
    name: ''
  }
  
  

  // 所属主题筛选
  get filtersTopicName () {
    return filterTableData(this.materialList, 'topic_name')
  }

  // 创建人筛选
  get filtersCreateName () {
    return filterTableData(this.materialList, 'create_name')
  }

  // 最后修改人筛选
  get filtersLastName () {
    return filterTableData(this.materialList, 'last_name')
  }

  // 问题类型列表
  get feedbackQuestionList () {
    // 查询接口数据
    const api = apiUnitConfig()
    var tmp: { text: any; value: any }[] = []
    api.then((res) => {
      for (const formKey in res) {
        var obj = {
          text: res[formKey].text,
          value: res[formKey].value

        }
        tmp.push(obj)
      }
    })
    return tmp
  }

  isOnline (scope: any) {
    if (scope.row.goods_topic_index_one) {
      return scope.row.goods_topic_index_one.online_status === 1
    }
  }

  handleAdd (ids:number) {
    this.form = {
      name: this.user.userInfo.name,
      info: '',
      form_id: ids
    }
    console.log(this)
    this.$refs.addUnit.openDialog()
  }

  // 弹出详情
  onFeedBack (ids:number) {
    // 查询详情 返回数据

    const api = apiFeedBackProcess(ids)

    api.then((res) => {
      this.form = {
        name: res.user_name,
        info: '',
        id: ids,
        descript: res.context,
        time: res.create_time
      }
    })

    this.$refs.addFeedBack.openDialog()
  }

  // 移动默认是关闭的
  @Watch('multipleSelection')
  onMultipleSelectionChange (newVal: any) {
    if (newVal.length > 0) {
      this.isFalse = false
    } else {
      this.isFalse = true
    }
  }

  // 所属主题筛选
  filterMethodCreateName (value: any, row: any) {
    return row.create_name === value
  }

  // 创建人筛选
  filterMethodTopicName (value: any, row: any) {
    return row.topic_name === value
  }

  // 问题类型筛选
  filterMethodLastName (value: any, row: any) {
    return row.type === value
  }

  created () {
    // 若 query 里有topicID，则当前页面是主题下的素材页面

    this.getMaterialLists()
  }

  rowStyle () {
    return 'text-align:center'
  }
  
  exportData()
  {
    // 批量导出数
    console.log('批量导出..需要前端来完成')

  }


  // 删除某行
  deleteRow (ids: any) {
    this.$confirm('确定删除选中的素材吗?', '删除提示').then(async () => {
      await apiDelSelectMaterial({
        ids: [ids]
      })
      await this.getMaterialLists()
      await this.$message({
        type: 'success',
        message: '删除成功!'
      })
    }).catch(() => {
    })
  }

  // 批量删除
  async delSelectTopic () {
    if (this.multipleSelection.length === 0) {
      return this.$message.error('请选择您需要删除的反馈！')
    }
    var data = []

    for (const formKey in this.multipleSelection) {
      for (const formKey1 in this.multipleSelection[formKey]) {
        // eslint-disable-next-line eqeqeq
        if (formKey1 == 'id') {
          data.push(this.multipleSelection[formKey][formKey1])
        }
      }
    }

    // 开始执行删除反馈
    const api = apiFeedBackDel({ data: data })
    api.then(() => {
    
    })
    await this.getMaterialLists()
    await this.$message({
      type: 'success',
      message: '删除成功!'
    })
    console.log(data)
  }

  // 选中的时候的状态
  handleSelectionChange (val: any) {
    this.multipleSelection = val
    const index: any = []
    this.multipleSelection.forEach((val: any) => {
      this.materialList.forEach((v: any, i: any) => {
        if (val.id === v.id) {
          index.push(i)
        }
      })
    })
    this.multipleIndex = _.sortBy(index)
  }

  // 编辑单行
  editRow (id: any) {
    const ids = [{ id: id }]
    apiGetMaterialByIds(ids).then((res) => {
      if (this.$route.params.data) {
        this.$router.push({
          name: 'new_material',
          params: {
            data: res,
            status: 'edit'
          }
        })
      } else {
        this.$router.push({
          name: 'new_material',
          params: {
            data: res
          }
        })
      }
    })
  }

  // 批量编辑
  goEdit () {
    if (this.multipleSelection.length === 0) {
      return this.$message.error('请选中您想编辑的主题！')
    }
    // let status = true
    // this.multipleSelection.forEach((item: any) => {
    //   // if的前者是为了避免当素材没有关联主题时报错
    //   if (item.goods_topic_index_one && item.goods_topic_index_one.online_status == 1) return status = false
    // })
    const isOnline = this.multipleSelection.some((item: any) => {
      // 前者是为了避免当素材没有关联主题时报错
      return item.goods_topic_index_one && item.goods_topic_index_one.online_status == 1
    })
    if (isOnline) {
      return commonAlert('您选中的素材中含有所在主题已发布的素材，不能编辑！', '编辑提示')
    }
    const ids = this.multipleSelection.map((item: any) => ({ id: item.id }))
    apiGetMaterialByIds(ids).then((res) => {
      this.$router.push({
        name: 'new_material',
        params: {
          data: res
        }
      })
    })
  }

  // 去编辑页
  goNewMaterial () {
    this.$router.push({
      path: '/content/new_material'
    })
  }

  // 配合table，使得页面刷新不会取消勾选
  getRowKey (row: any) {
    return row.id
  }

  // 获取所有主题
  async getTopic (value: any) {
    const {
      audio,
      calendar,
      paint
    } = await apiGetFormatTopic({
      search: value
    })
    this.topicList = [{
      label: '画册模式',
      options: { ...paint }
    }, {
      label: '音箱模式',
      options: { ...audio }
    }, {
      label: '台历模式',
      options: { ...calendar }
    }]
  }

  // 获取搜索的素材列表
  async getSearchLists () {
    await this.pager.request({
      callback: apiFeedBackLists, // 获取用户反馈模块
      params: {
        ...this.form,
        is_search_engine: 1
      }
    }).then((res: any) => {
      this.materialList = res.lists
    })
    this.$nextTick(() => {
      this.$refs.table.clearSelection()
    })
  }

  // 获取用户反馈列表
  async getMaterialLists () {
    await this.pager.request({
      callback: apiFeedBackLists,
      params: {
        ...this.form
      }
    }).then((res: any) => {
      console.log(res)
      this.materialList = res.lists
    })
    this.$nextTick(() => {
      this.$refs.table.clearSelection()
    })
  }

  // 获取主题下所有素材列表
  async getAllMaterialForTopic (topicId: string) {
    await apiGetAllMaterialForTopic({
      topic_id: topicId
    }).then(res => {
      this.materialList = res
    })
  }

  // 根据id获取索引
  getIndexById (data: any) {
    const selectIndex: any = []
    data.forEach((item: any) => {
      selectIndex.push(this.materialList.findIndex((i: any) => i.id == item.id))
    })
    return selectIndex
  }

  /**
   * 先获取选中的索引
   * 不用element ui的多选索引是因为不触发选中，索引就不会更新
   * 所以要自己获取
   * @param type 1.置顶，0置底
   */
  async setTopORBottom (type: number) {
    const selectIndex: any = this.getIndexById(this.multipleSelection)
    // 根据索引遍历，删除索引所在的值，存入data中
    const data: any = []
    let tableData: any = this.materialList
    selectIndex.forEach((item: any) => {
      data.push(tableData[item])
      delete tableData[item]
    })
    // delete会删除索引把索引变空，用filter去掉empty
    tableData = tableData.filter((item: any) => item)
    data.forEach((item: any) => {
      if (type === 1) {
        tableData.unshift(item)
      } else if (type === 0) {
        tableData.push(item)
      }
    })
    await this.setLocation(tableData)
  }

  /**
   * 移动
   * @param type 1.上移，0下移
   */
  async move (type: number) {
    let selectIndex: any = this.getIndexById(this.multipleSelection)
    // 获取表格所有数据
    const tableData: any = this.materialList
    // 如果上移，索引正序排序，反之倒序排序
    selectIndex = type === 1 ? selectIndex.sort() : selectIndex.sort((a: any, b: any) => b - a)
    selectIndex.forEach((index: number) => {
      let neighbor: any = type === 1 ? index - 1 : index + 1
      if (neighbor < 0) {
        neighbor = 0
      } else if (neighbor > tableData.length - 1) {
        neighbor = tableData.length - 1
      }
      const data = tableData[neighbor]
      tableData[neighbor] = tableData[index]
      tableData[index] = data
    })
    await this.setLocation(tableData)
  }

  // 根据录入的排序字段更新
  async setLocation (data: any) {
    const order_data: any = []
    data.forEach((item: any) => {
      order_data.push({
        id: item.id
      })
    })
    await apiSetTopOrderAll({
      order_data,
      topic_id: this.form.topic_id
    })
    await this.getMaterialLists()
  }
}
