


















































import { apiFeedBackConfig, apiUnitEdit } from '@/api/feedback'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
@Component({
  components: {
    LsDialog
  }
})
export default class AddUnit extends Vue {
  $refs!: { lsDialog: any; form: any }
  @Prop() value: any

  @Watch('value', { deep: true })
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  valueChange () {
    this.$nextTick(() => {
      this.$refs.form.clearValidate()
    })
  }

  closeDialog () {
    this.$refs.lsDialog.close()
  }

  openDialog () {
    console.log(this.$refs.form)
    this.$refs.lsDialog.open()
  }

  handleSave () {
    console.log(this.value)
    var data = {
      id: this.value.form_id,
      info: this.value.descript
    }
 
     this.$refs.form.validate((valid: boolean, object: any) => {
      if (valid) {
        const api = this.value.id
          ? apiUnitEdit(this.value)
          // eslint-disable-next-line no-undef
          : apiFeedBackConfig(data)
        api.then(() => {
          this.closeDialog()
          this.$emit('refresh')
        })
      } else {
        return false
      }
    })
  }
}
